<template>
  <g
    fill="none"
    fill-rule="evenodd"
    :stroke="color"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
  >
    <path
      d="M3.25 7.495L9.75 7.495M3.25 10.707L9.75 10.707M11.917 14.99H1.083C.485 14.99 0 14.512 0 13.92V1.07C0 .48.485 0 1.083 0h10.834C12.515 0 13 .479 13 1.07v12.85c0 .592-.485 1.07-1.083 1.07zM3.25 4.283L7.583 4.283"
      transform="translate(1 1)"
    />
  </g>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
